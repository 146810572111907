import React from "react";
import { Box } from "@mui/material";
import HeroImage from "../../components/HeroImage/HeroImage";
import pitmasterBg from "../../Assets/image12.png";
import PitMasterContent from "./PitMaster Content/PitMasterContent";

const PitMaster = () => {
  return (
    <Box>
      <HeroImage image={pitmasterBg} text="PIT MASTER" />
      <PitMasterContent />
    </Box>
  );
};

export default PitMaster;
