import React from "react";
import BbqQuoteContent from "./Bbq Quote Content/BbqQuoteContent";
import HeroImage from "../../components/HeroImage/HeroImage";
import { Box } from "@mui/material";
import bg from "../../Assets/image4.jpg";

const GetBbqQuote = () => {
  return (
    <Box>
      <HeroImage image={bg} text="GET BBQ QUOTE" />
      <BbqQuoteContent />
    </Box>
  );
};

export default GetBbqQuote;
