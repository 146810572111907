import React from "react";
import { useState } from "react";
import {
  Container,
  AppBar,
  Typography,
  Toolbar,
  Link,
  Button,
  Box,
  List,
  Divider,
  Drawer,
  Card,
} from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";

import logo from "../../Assets/dfw-logo.png";
import Image from "mui-image";
const Navbar = () => {
  const [clicked, setclicked] = useState(false);

  const clickHandler = () => {
    setclicked(true);
  };

  const backHandler = () => [setclicked(false)];

  const list = () => (
    <Box
      sx={{ py: 7, width: "100%" }}
      anchor="left"
      role="presentation"
      onClick={backHandler}
      onKeyDown={{ backHandler }}
    >
      <List
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <Divider />
        <Link href="/" color={"inherit"} underline="none" sx={linkSX}>
          <Typography sx={{ fontSize: "1.6rem" }} variant="h6">
            Home
          </Typography>
        </Link>
        <Divider />
        <Link href="/menu" color={"inherit"} underline="none" sx={linkSX}>
          <Typography sx={{ fontSize: "1.6rem" }} variant="h6">
            Caterin' Menu
          </Typography>
        </Link>
        <Divider />
        <Link href="/bbqquote" color={"inherit"} underline="none" sx={linkSX}>
          <Typography sx={{ fontSize: "1.6rem" }} variant="h6">
            Get BBQ Quote
          </Typography>
        </Link>
        <Divider />
        <Link href="/aboutus" color={"inherit"} underline="none" sx={linkSX}>
          <Typography sx={{ fontSize: "1.6rem" }} variant="h6">
            Who Dat'
          </Typography>
        </Link>
        <Divider />
      </List>
    </Box>
  );

  const linkSX = {
    padding: "0 50px",
    alignItems: "center",
    fontFamily: "Wallpoet",
    "&:hover": {
      color: "grey",
    },
  };
  return (
    <AppBar
      position="sticky"
      color="primary"
      sx={{
        backgroundColor: "primary.dark",
      }}
    >
      <Container
        sx={{
          alignItems: "center",
          height: "100px",
          display: "flex",
          justifyContent: "center",
        }}
        maxWidth="xl"
      >
        <Card
          sx={{
            flexShrink: 0,
            height: "95px",
            position: "relative",
            backgroundColor: "primary.dark",
            boxShadow: "0",
          }}
        >
          <Link href="/">
            <Image
              src={logo}
              duration="0"
              style={{
                height: "90px",
                width: "120px",
              }}
            />
          </Link>
        </Card>
        <Box
          sx={{
            display: {
              xs: "flex",
              md: "none",
              position: "absolute",
              left: "2%",
            },
          }}
        >
          <Button onClick={clickHandler}>
            <MenuIcon sx={{ color: "white", fontSize: "35px" }} />
          </Button>

          <Drawer
            sx={{
              color: "primary.dark",
            }}
            anchor="left"
            open={clicked}
            onClose={backHandler}
          >
            {list("left")}
          </Drawer>
        </Box>
        <Toolbar
          sx={{
            display: { xs: "none", md: "flex" },
          }}
        >
          <Link href="/" color={"inherit"} underline="none" sx={linkSX}>
            <Typography sx={{ fontSize: "1.5rem" }} variant="h6">
              Home
            </Typography>
          </Link>
          <Link href="/menu" color={"inherit"} underline="none" sx={linkSX}>
            <Typography sx={{ fontSize: "1.5rem" }} variant="h6">
              Caterin' Menu
            </Typography>
          </Link>
          <Link href="/bbqquote" color={"inherit"} underline="none" sx={linkSX}>
            <Typography sx={{ fontSize: "1.5rem" }} variant="h6">
              Get BBQ Quote
            </Typography>
          </Link>
          <Link href="/aboutus" color={"inherit"} underline="none" sx={linkSX}>
            <Typography sx={{ fontSize: "1.5rem" }} variant="h6">
              Who Dat'
            </Typography>
          </Link>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Navbar;
