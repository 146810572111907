import React from "react";
import { Box } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import menuChart1 from "../../../Assets/menu-chart.png";
import menuChart2 from "../../../Assets/holiday meal.png";
import txMapWhite from "../../../Assets/tx-map-white.png";
import cateringMenu from "../../../Assets/catering-menu.png";
import Image from "mui-image";

const MenuContent = () => {
  return (
    <Box
      sx={{
        py: 5,
        backgroundImage: `url("https://www.transparenttextures.com/patterns/retina-wood.png")`,
      }}
    >
      <Box
        sx={{
          py: 3,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <Card
          sx={{
            width: { md: "95%", lg: "60%" },
            backgroundImage: `url("https://www.transparenttextures.com/patterns/retina-wood.png")`,
          }}
        >
          <CardMedia
            component="img"
            sx={{
              height: { xs: "auto", md: "auto" },
              objectFit: { xs: "fill", md: "contain" },
              border: "5px solid #ffff",
              borderRadius: "10px",
            }}
            image={cateringMenu}
          />
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 3,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography variant="h5" color="text.secondary">
              Enjoy the best BBQ meal
            </Typography>
            <Button
              href="https://drive.google.com/file/d/1UlvSzYH3EOE7i8l--u5as8aQQCSIFL-5/view?usp=share_link"
              sx={{
                textAlign: "center",
                width: "50%",
              }}
              variant="outlined"
              size="large"
              color="primary"
            >
              Download Menu
            </Button>
          </CardContent>
        </Card>
      </Box>

      <Box
        sx={{
          py: 3,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <Card
          sx={{
            width: { md: "95%", lg: "60%" },
            backgroundImage: `url("https://www.transparenttextures.com/patterns/retina-wood.png")`,
          }}
        >
          <CardMedia
            component="img"
            sx={{
              height: { xs: "auto", md: "130vh" },
              objectFit: { xs: "fill", md: "contain" },
              border: "5px solid #ffff",
              borderRadius: "10px",
            }}
            image={menuChart1}
          />
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 3,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography variant="h5" color="text.secondary">
              Enjoy the best BBQ meal
            </Typography>
            <Button
              href="https://drive.google.com/file/d/1v7estBrhrKW0mo4JJSPqxyk9Joymz-vr/view?usp=sharing"
              sx={{
                textAlign: "center",
                width: "50%",
              }}
              variant="outlined"
              size="large"
              color="primary"
            >
              Download Menu
            </Button>
          </CardContent>
        </Card>
      </Box>
      <Box sx={{ p: { xs: 0, md: 4 } }}>
        <Image src={txMapWhite} style={{ height: "300px", width: "300px" }} />
      </Box>
      <Box
        sx={{
          p: 2,
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography sx={{ p: 2 }} variant="h2">
          Available Now <br /> Nov 14, 2022 - Jan 1, 2023
        </Typography>
        <Typography sx={{ p: 2, width: "60%" }} variant="h4">
          Fine Authentic Texas BBQ Holiday Meals are available with 48hrs
          advance notice.
        </Typography>
      </Box>
      <Box
        sx={{
          py: 3,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <Card
          sx={{
            width: { md: "95%", lg: "60%" },
            backgroundImage: `url("https://www.transparenttextures.com/patterns/retina-wood.png")`,
          }}
        >
          <CardMedia
            component="img"
            sx={{
              height: { xs: "auto", md: "130vh" },
              objectFit: { xs: "fill", md: "contain" },
              border: "5px solid #ffff",
              borderRadius: "10px",
            }}
            image={menuChart2}
          />
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 3,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography variant="h5" color="text.secondary">
              Try Our Holiday Meals Too
            </Typography>
            <Button
              href="https://drive.google.com/file/d/1TDkmG_sloPswgcBEBIhi2ZvzoV8uxMCO/view?usp=sharing"
              sx={{ textAlign: "center", width: "50%" }}
              variant="outlined"
              size="large"
              color="primary"
            >
              Download Menu
            </Button>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default MenuContent;
