import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { blue, blueGrey } from "@mui/material/colors";

const theme = responsiveFontSizes(
  createTheme({
    palette: {
      mode: "light",
      primary: {
        main: blue[900],
      },
      secondary: {
        main: blue[500],
      },
      background: {
        default: blueGrey[700],
        paper: blueGrey[700],
      },
      text: {
        primary: "rgba(255,254,254,0.87)",
        secondary: "rgba(255,254,254,0.87)",
      },
    },
    typography: {
      h1: {
        fontFamily: ' "Rubik Distressed", cursive;',
      },
      h2: {
        fontFamily: ' "Rubik Distressed", cursive;',
      },
      h3: {
        fontFamily: ' "Rubik Distressed", cursive;',
      },
      h4: {
        fontFamily: ' "Rubik Distressed", cursive;',
      },
      h5: {
        fontFamily: ' "Rubik Distressed", cursive;',
      },
      h6: {
        fontSize: "1.5rem",
        fontFamily: ' "Rubik Distressed", cursive;',
      },
    },
  })
);

export default theme;
