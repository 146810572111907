import { React } from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { Typography, Box } from "@mui/material";

import image1 from "../../../Assets/image1.jpg";
import image2 from "../../../Assets/image2.jpg";
import image3 from "../../../Assets/image3.jpg";
import image4 from "../../../Assets/image4.jpg";
import image5 from "../../../Assets/image5.jpg";
import image6 from "../../../Assets/image6.jpg";
import image7 from "../../../Assets/image7.jpg";
import image8 from "../../../Assets/image8.jpg";
import image10 from "../../../Assets/image10.jpg";
import image11 from "../../../Assets/image11.jpg";
import image12 from "../../../Assets/image16.jpg";

import bgImage from "../../../Assets/wooden-bg.jpg";

function srcset(image, width, height, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${width * cols}&h=${height * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${width * cols}&h=${
      height * rows
    }&fit=crop&auto=format&dpr=1 1x`,
  };
}

const CustomImageList = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        py: 7,
        pb: 12,

        backgroundImage: `url(${bgImage})`,
        backgroundAttachment: "fixed",
      }}
    >
      <Typography sx={{ padding: "30px 0", textAlign: "center" }} variant="h2">
        Servin' The Very Best BBQ!
      </Typography>
      <ImageList
        sx={{
          width: { xs: "90%", sm: "80%", md: "70%", lg: "60%" },
          height: { xs: "50vh", sm: "60vh", md: "80vh", lg: "90vh" },
          // Promote the list into its own layer in Chrome. This costs memory, but helps keeping high FPS.
          transform: "translateZ(0)",
          border: "5px solid white",
          borderRadius: "7px",
        }}
        rowHeight="auto"
        gap={1}
      >
        {itemData.map((item) => {
          const cols = item.featured ? 3 : 2;
          const rows = item.featured ? 3 : 2;

          return (
            <ImageListItem key={item.img} cols={cols} rows={rows}>
              <img
                {...srcset(item.img, 250, 200, rows, cols)}
                alt={item.title}
                loading="lazy"
              />
            </ImageListItem>
          );
        })}
      </ImageList>
    </Box>
  );
};

const itemData = [
  {
    img: image1,
  },
  {
    img: image2,
  },
  {
    img: image3,
  },
  {
    img: image4,
  },
  {
    img: image5,
  },
  {
    img: image6,
  },
  {
    img: image7,
  },
  {
    img: image8,
  },
  {
    img: image10,
  },
  {
    img: image11,
  },
  { img: image12 },
];

export default CustomImageList;
