import React from "react";
import { Box } from "@mui/material";
import Hero from "./Hero/Hero";
import HomeDetails from "./HomeDetails/HomeDetails";
import CustomImageList from "./CustomImageList/CustomImageList";
import { Helmet } from "react-helmet";

const Home = () => {
  return (
    <Box>
      <Helmet>
        <link rel="canonical" href="https://dfwbbqcatering.co/" />
      </Helmet>
      <Hero />
      <HomeDetails />
      <CustomImageList />
    </Box>
  );
};

export default Home;
