import React from "react";
import { Box } from "@mui/material";
import MenuContent from "./MenuContent/MenuContent";
import HeroImage from "../../components/HeroImage/HeroImage";
import bg from "../../Assets/image6.jpg";
const CaterinMenu = () => {
  return (
    <Box>
      <HeroImage image={bg} text="CATERIN' MENU" />
      <MenuContent />
    </Box>
  );
};

export default CaterinMenu;
