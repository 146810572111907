import React from "react";
import { ThemeProvider, CssBaseline } from "@mui/material";
import Theme from "./theme/Theme";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import CaterinMenu from "./pages/CaterinMenu/CaterinMenu";
import GetBbqQuote from "./pages/GetBbqQuote/GetBbqQuote";
import PitMaster from "./pages/PitMaster/PitMaster";

function App() {
  return (
    <ThemeProvider theme={Theme}>
      <CssBaseline />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/menu" element={<CaterinMenu />} />
        <Route path="/bbqquote" element={<GetBbqQuote />} />
        <Route path="/aboutus" element={<PitMaster />} />
      </Routes>
      <Footer />
    </ThemeProvider>
  );
}

export default App;
